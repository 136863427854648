import Button from "@/components/Common/Buttons/Button/Button";
import AboutButton from "@/components/Common/Buttons/AboutButton/AboutButton";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import InputField from "@/components/Common/Inputs/Input/Input";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import PrinceRangeSlider from "@/components/Components/PriceRangeSlider/PriceRangeSlider";
import MarketplaceAd from "@/components/Components/MarketplaceAd/MarketplaceAd";
import PropertyAd from "@/components/Components/PropertyAd/PropertyAd";
import MarketplaceSortFilter from "@/views/MarketplaceCategoryPage/MarketplaceSortFilter/MarketplaceSortFilter";


export default {
    name: 'ContactPage',
    components: {

        Button,
        CheckBox,
        PrinceRangeSlider,
        MarketplaceAd,
        PropertyAd,
        AboutButton,
        MarketplaceSortFilter,
        InputField,
        TextArea
    },
    data() {
        return {
            activeGrid: 'properties',
            contact_details: {
                phone: '0888191616',
                email: "contact@sakvartirantite.com",
                technical_support_phone: '0888 88 88 88',
                location: this.$t('company-location'),
            },
            form: null,
            formErrors: [],
        }
    },
    methods: {
        initForm() {
            return {
                name: null,
                phone: null,
                email: null,
                message: null,
            }
        },
        async sendMessage() {

            this.formErrors = [];

            if (this.form.name === null || this.form.name === '' || this.form.name.length < 2) {
                this.formErrors.push('name');
            }

            if (this.form.phone === null || this.form.phone === '' || this.form.phone.length < 5) {
                this.formErrors.push('phone');
            }

            if (this.form.email === null || this.form.email === '' || this.form.email.length < 6) {
                this.formErrors.push('email');
            }

            if (this.form.message === null || this.form.message === '' || this.form.message.message < 10) {
                this.formErrors.push('message');
            }

            if (this.formErrors.length > 0) {
                return;
            }

            try {
                await this.$store.dispatch('utils/sendContactForm', this.form);
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'success',
                    message: this.$t('message-successfully-sent')
                });
                this.form = this.initForm();
            } catch (err) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error',
                    message: this.$t('message-sendin-failed')
                });
            }
        }
    },
    mounted() {
        this.form = this.initForm();
    },
}
